<template>
  <img :alt="`${name} flag`" :src="imgSrc" :loading="lazy ? 'lazy' : 'eager'" />
</template>

<script setup lang="ts">
import DE from '@brand/assets/flags/de.svg?url';
import DK from '@brand/assets/flags/dk.svg?url';
import FI from '@brand/assets/flags/fi.svg?url';
import NO from '@brand/assets/flags/no.svg?url';
import SE from '@brand/assets/flags/se.svg?url';
import SV from '@brand/assets/flags/sv.svg?url';
import UK from '@brand/assets/flags/uk.svg?url';
import US from '@brand/assets/flags/us.svg?url';

const flags = {
  de: DE,
  dk: DK,
  fi: FI,
  no: NO,
  se: SE,
  sv: SV,
  uk: UK,
  us: US,
};

interface Props {
  code: string;
  name?: string;
  lazy?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  lazy: false,
  name: '',
});

const imgSrc = computed(() => flags[props.code as keyof typeof flags]);

export type { Props };
</script>
